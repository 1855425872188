<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="交易日期">
              <a-range-picker @change="onDateChange" v-model="defaultDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="认领日期">
              <a-range-picker @change="onClaimDateChange" v-model="claimDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="收款人开户行">
              <a-select
                v-model="queryParam['bank_id']"
                showSearch
                allowClear
                placeholder="请选择收款人开户行"
              >
                <a-select-option :value="item.id" v-for="item in bankOps" :key="item.id">
                  {{ item.bank }}/{{ item.bank_account }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="币种">
              <a-input v-model.trim="queryParam['currency']" placeholder="根据币种搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="状态" prop="flag">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam['flag']"
                @change="moneyFlagChange"
                placeholder="请选择状态"
              >
                <a-select-option v-for="op in moneyFlagOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款人名称">
              <a-input v-model.trim="queryParam['pay_name']" placeholder="根据付款人名称搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="银行流水号">
              <a-input v-model.trim="queryParam['bank_serial_number']" placeholder="根据银行流水号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input v-model.trim="queryParam['invoice_no']" placeholder="根据发票号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="账款类型">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam['is_advance']"
                @change="isAdvanceFlagChange"
                placeholder="请选择账款类型"
              >
                <a-select-option v-for="op in isAdvanceOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <!-- <a-button type="primary" @click="handleAccountType" :disabled="selectedRowKeys.length <= 0">设置账款类型</a-button> -->
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 'max-content' }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="certInfo" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item.id">{{ item.cert_num + '(' + transCertStatus(item.cert_status) + ')' }}</span>
      </div>
      <div slot="invoiceNoList" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a v-if="record.flag===2 && record.pay_name !=='通联支付网络服务股份有限公司'" @click="applyBankRefund(record)">申请退款</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="applyBankRefundVisible"
      :confirmLoading="applyBankRefundLoading"
      :width="800"
      :maskClosable="false"
      title="申请退款"
      @ok="confirmRefundApply"
      @cancel="applyBankRefundVisible = false"
    >
      <a-form layout="inline" :model="moneyRefundApplyForm" :rules="RefundApplyRules" >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="银行流水号">
              {{this.currentMoneyRecord.bank_serial_number}}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="付款人">
              {{this.currentMoneyRecord.pay_name}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="交易货币">
              {{this.currentMoneyRecord.currency}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="交易金额">
              {{this.currentMoneyRecord.amount}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="认领金额">
              {{this.currentMoneyRecord.claim_amount}}
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="退款金额">
              {{this.currentMoneyRecord.refund_amount}}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-divider orientation="left" style="font-weight:700">
      </a-divider>
      <a-form-model layout="inline" ref="moneyRefundApplyForm" :model="moneyRefundApplyForm" :rules="RefundApplyRules" >
        <a-row :gutter="24">
          <a-col :span="16">
            <a-form-model-item label="退款客户" prop="refund_customer_id">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择退款客户"
                style="width: 360px"
                :value="payerCustomerInfo"
                :filter-option="false"
                :not-found-content="payerFetching ? undefined : null"
                @search="getPayerCustomer"
                @change="changePayerCustomerInfo"
              >
                <template v-if="payerFetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in payerCustomerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="退款金额" prop="refund_amount">
              <a-input-number v-model.trim="moneyRefundApplyForm.refund_amount" style="width: 100px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="退款原因" prop="refund_reason">
              <a-textarea v-model.trim="moneyRefundApplyForm.refund_reason" style="width: 400px"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getSystemPage } from '@/api/system'
import {
  getClaimPage,
  getFmsMetaOption,
  editIsAdvance,
  handleMoneyRefundApply
} from '@/api/fms'
import { getCustomer, downloadExcel } from '@/api/common'
import debounce from 'lodash/debounce'
import { Empty } from 'ant-design-vue'
export default {
  components: {
    STable,
    Empty
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 400)
    return {
      bankItem: '',
      showTotal: '合计：0',
      formVisible: false,
      confirmLoading: false,
      queryParam: {
        department: this.$store.getters.userInfo.department
        // flag: 2
      },
      defaultDate: [],
      claimDate: [],
      userOps: [],
      customerOps: [],
      claimAmounts: [],
      bankOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '银行流水号',
          dataIndex: 'bank_serial_number'
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: (text) => {
            if (text === 0) return '-'
            if (text === 100) return '混合部门'
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '账款类型',
          dataIndex: 'is_advance',
          customRender: text => {
            switch (text) {
              case 0:
                return '未设置'
              case 1:
                return '非预收'
              case 2:
                return '预收'
            }
          }
        },
        {
          title: '状态',
          dataIndex: 'flag',
          customRender: text => {
            switch (text) {
              case 0:
                return '未认领'
              case 1:
                return '已认领'
              case 2:
                return '部分认领'
            }
          }
        },
        {
          title: '交易日',
          dataIndex: 'arrive_date',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '付款人名称',
          dataIndex: 'pay_name',
          width: 300
        },
        {
          title: '交易货币',
          dataIndex: 'currency'
        },
        {
          title: '交易金额',
          dataIndex: 'amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '退款金额',
          dataIndex: 'refund_amount',
          customRender: (text) => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: `已认领金额`,
          dataIndex: `claim_amount`,
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_no_list',
          scopedSlots: { customRender: 'invoiceNoList' }
        },
        {
          title: '交易附言',
          dataIndex: 'remark',
          width: 300
        },
        {
          title: '收款人账号',
          dataIndex: 'receive_account'
        },
        {
          title: '收款人开户行',
          dataIndex: 'receive_bank'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      form: {
        claim_date: moment(),
        claimant: this.$store.getters.userInfo.id,
        department: this.$store.getters.userInfo.department,
        invoice_no: '',
        customerId: undefined,
        invoice_start_date: null,
        invoice_end_date: null
      },
      defaultInvoiceDate: [],
      selectedRowKeys: [],
      businessCheckList: [],
      moneyFlagOps: [
        {
          value: 0,
          name: '未认领'
        },
        {
          value: 1,
          name: '已认领'
        },
        {
          value: 2,
          name: `部分认领`
        }
      ],
      isAdvanceOps: [
        {
          value: 1,
          name: '非预收'
        },
        {
          value: 2,
          name: '预收'
        },
        {
          value: 0,
          name: '未设置'
        }
      ],
      loadData: parameter => {
        // if (!this.queryParam.department || this.queryParam.department === 0) {
        //   return {
        //     list: [],
        //     total: 0
        //   }
        // }
        return getClaimPage(Object.assign(parameter, this.queryParam)).then(res => {
          return {
            list: res.records,
            total: res.total
          }
        })
      },
      fetching: false,
      payName: null,
      accountTypeVisible: false,
      accountType: 1,
      accountDepartment: undefined,
      dealDate: undefined,
      endDealDate: undefined,
      certPrintData: [],
      certPrintName: '',
      currentMoneyRecord: {},
      certStatusOps: [
        {
          value: 1,
          name: '待确认'
        },
        {
          value: 2,
          name: '已确认'
        },
        {
          value: 3,
          name: '已下载'
        }
      ],
      applyBankRefundVisible: false,
      applyBankRefundLoading: false,
      payerCustomerOps: [],
      payerCustomerId: null,
      payerCustomerInfo: undefined,
      payerFetching: false,
      updatePayerId: null,
      moneyRefundApplyForm: {
        money_register_id: null,
        department: this.$store.getters.userInfo.department,
        refund_customer_id: null,
        refund_amount: null,
        refund_reason: null
      },
      RefundApplyRules: {
        refund_customer_id: [{ required: true, message: '请选择客户', trigger: 'blur' }],
        refund_amount: [{ required: true, message: '请填写退款金额', trigger: 'blur' }],
        refund_reason: [{ required: true, message: '请填写退款原因', trigger: 'blur' }]
      }
    }
  },
  created() {
    getSystemPage('user', {
      page: 1,
      page_size: 1000
    }).then(v => {
      this.userOps = v.list
    })
    getFmsMetaOption('fms_bank').then(v => {
      this.bankOps = v
    })
  },
  watch: {
    selectedOrder() {
      let total = 0
      for (const item of this.orderData) {
        if (this.selectedOrder.includes(item.id)) {
          total += item.can_claim_amount
        }
      }
      this.showTotal = `合计：${total}`
    }
  },
  methods: {
    moment,
    // 编辑付款人
    applyBankRefund(record) {
      if (record) {
        this.currentMoneyRecord = record
        this.getPayerCustomer(record.pay_name)
        this.moneyRefundApplyForm.money_register_id = record.id
        this.moneyRefundApplyForm.refund_amount = (record.amount - record.claim_amount - record.refund_amount).toFixed(2)
        this.applyBankRefundVisible = true
      }
    },
    // 提交指定付款人
    confirmRefundApply() {
      this.moneyRefundApplyForm.refund_customer_id = this.payerCustomerId
      this.$refs.moneyRefundApplyForm.validate(valid => {
        if (valid) {
          handleMoneyRefundApply(this.moneyRefundApplyForm).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '申请退款成功'
            })
            this.applyBankRefundVisible = false
          })
          .finally(_ => {
            this.applyBankRefundLoading = false
          })
        }
      })
    },
    getPayerCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.payerCustomerOps = []
      this.payerFetching = true
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        if (res && res.length > 0) {
          this.payerCustomerInfo = {
            'key': res[0].value, 'label': res[0].name
          }
          this.payerCustomerId = res[0].value
        } else {
          this.payerCustomerInfo = undefined
          this.payerCustomerId = null
        }
        this.payerCustomerOps = res
        this.payerFetching = false
      })
    },
    changePayerCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.payerCustomerInfo = undefined;
        this.payerCustomerId = null;
      } else {
        // 委托客户选择框中有值
        this.payerCustomerInfo = value;
        this.payerCustomerId = value.key;
      }
      this.payerCustomerOps = []
      this.payerFetching = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    departmentChange(value) {
      this.getDepartmentCustomer(this.payName)
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: this.form.department,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        if (res && res.length > 0) {
          this.form.customerId = res[0].value
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    onInvoiceDateChange(date) {
      if (date[0]) {
        this.form.invoice_start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.form.invoice_end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.form.invoice_start_date = null
        this.form.invoice_end_date = null
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        const _date = [
          date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        ]
        this.queryParam['arrive_date'] = _date
      } else {
        this.queryParam['arrive_date'] = []
      }
    },
    moneyFlagChange() {
      this.$refs.table.refresh(false)
    },
    isAdvanceFlagChange() {
      this.$refs.table.refresh(false)
    },
    handleAccountType() {
      this.accountTypeVisible = true
      this.accountType = 1
    },
    onDealDateChange(date, dateString) {
      if (date[0]) {
        this.dealDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.endDealDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.dealDate = null
        this.endDealDate = null
      }
    },
    // 账款类型
    handleSubmitAccountType() {
      this.confirmLoading = true
      editIsAdvance({
        ids: this.selectedRowKeys,
        account_type: this.accountType,
        account_department: this.accountDepartment,
        start_deal_date: this.dealDate,
        end_deal_date: this.endDealDate
      })
        .then(_ => {
          this.$notification['success']({
            message: '提示',
            description: `资金已设置为${this.accountType === 1 ? '非预收' : '预收'}`
          })
          this.$refs.table.refresh(false)
          this.handleCloseAccountType()
        })
        .finally(_ => {
          this.confirmLoading = false
        })
    },
    handleCloseAccountType() {
      this.accountTypeVisible = false
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    moneyFormatter(money) {
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    transCertStatus(status) {
      switch (status) {
        case 1:
          return '待确认'
        case 2:
          return '已确认'
        case 3:
          return '已下载'
        default:
          return ''
      }
    },
    onClaimDateChange(date, dateString) {
      if (date[0]) {
        const _date = [
          date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        ]
        this.queryParam['claim_date'] = _date
      } else {
        this.queryParam['claim_date'] = []
      }
    }
  }
}
</script>
